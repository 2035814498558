<template>
  <a href="https://www.artic.edu/" class="articLogo">
    <span>ART</span>
    <span>INSTITUTE</span>
    <span>CHICAGO</span>
  </a>
</template>

<style scoped>
.articLogo {
  height: 100px;
  width: 80px;
  background-color: #b50938;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 4px;
  text-decoration: none;
  font-size: 0.8rem;
}
</style>
